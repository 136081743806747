import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useState, createContext } from 'react';

import { supportedLngs } from 'src/i18n';

const DoctorContext = createContext({});

const roleAccess = {
  'GP admin': ['gp', 'patients', /* screening */ 'details', 'redirect', 'invites'],
  'Medical staff - doctor': ['doctor', 'patients', 'screening', 'details', 'redirect', 'invites'],
  'Medical staff - nurse': ['nurse', 'patients', 'screening', 'details', 'redirect', 'invites'],
};

export const DoctorProvider = ({ children }) => {
  const [currentPatient, setCurrentPatient] = useState(null);
  const [lang, setLang] = useState(i18next.language);
  const [doctor, setDoctor] = useState();

  const getPermissions = (groups) => {
    const permissions = [];
    groups.forEach((group) => {
      roleAccess[group]?.forEach((permission) => {
        if (!permissions.includes(permission)) permissions.push(permission);
      });
    });
    return permissions;
  };

  const modifyDoctor = (oldDoctor) => {
    const modifiedDoctor = {
      ...oldDoctor,
      displayName: `${oldDoctor.first_name} ${oldDoctor.last_name}`,
      access: getPermissions(oldDoctor.groups),
    };

    modifyLanguage(modifiedDoctor.language);
    setDoctor(modifiedDoctor);
  };

  const modifyLanguage = (languageInput) => {
    let language = languageInput.split('-');
    language = language.length > 1 ? `${language[0]}-${language[1].toUpperCase()}` : language[0];

    if (supportedLngs.includes(language)) {
      i18next.changeLanguage(language);
      setLang(language);
    }
  };
  return (
    <DoctorContext.Provider
      value={{
        lang,
        setLang: modifyLanguage,
        doctor,
        setDoctor: modifyDoctor,
        currentPatient,
        setCurrentPatient,
      }}
    >
      {children}
    </DoctorContext.Provider>
  );
};

export default DoctorContext;

DoctorProvider.propTypes = {
  children: PropTypes.any,
};
